<template>
	<section class="_list">
		<div class="sec_wrap" id="listArea">
			<h2 class="section_title">
				<span class="main_title">{{mainTitle}}</span>
				<span class="sub_title">{{subTitle}}</span>
			</h2>
			
			<ul id="dataList" class="con_list">
				<data-item 
					v-for="(item, index) in items" :key="index" 
						:index="index"
						:episode="item.episode"
						:dateYear="item.dateYear"
						:dateMonth="item.dateMonth"
						:dateWeek="item.dateWeek"
						:bookCode="item.bookCode"
						:chapter="item.chapter"
						:verseStart="item.verseStart"
						:verseEnd="item.verseEnd"
						:youtubeCode="item.youtubeCode"
						:title="item.title"
						:fileName="item.fileName"
						:fileURL="item.fileS3Link"
						:fileType="item.fileType"
				/>
			</ul>
			<nav id="pagingList" class="paging_list">
				<a 
					v-for="(pageItem, index) in pageList" :key="index" 
					:href="pageItem.link" 
					title="link to list" 
					v-on:click="changePage($event, pageItem.link)"
					:class="'paging '+pageItem.css">{{pageItem.code}}</a>
			</nav>
			<div class="search_area" id="searchArea"></div>
		</div>
	</section>
</template>

<script>
import DataItem from '@/components/DataItem';
import axios from 'axios'


export default {
    name: 'DataList',
	components: {
		DataItem
	},
    data: () => ({
		items:[],
		pageList:[]
    }),
	props:{
		page:{type: Number, default: 0},
		searchType:{type: String, default: ''},
		searchKeyword:{type: String, default: ''},
	},
    computed: {
		lang(){
			return this.$store.state.lang;
		},
		mainTitle(){
			return this.$store.state.headerTitle("data", "main");
		},
		subTitle(){
			return this.$store.state.headerTitle("data", "sub");
		}
    },
	methods: {
		loadData() {
			let vm= this, 
				data= {
					page:vm.page,
					lang:vm.lang,
				}, 
				path=vm.$store.state.API_PATH+'episode/episodefiles';
				if (process.env.NODE_ENV == "development") {
					path+=".json";
				}
			axios.get(
                    path, 
                    {params: data}
                )
                .then((res) =>{
					let data = res.data;
                    if (!data.result) return;
                    vm.items = data.data;
					vm.pageList = vm.buildPageList(data.pagination)
                })
                .catch((error)=>{
                    console.warn(error);
                })
		},
		buildPageList(pageData) {
			var pageGroupEa=5,
				num=pageData.pageTotal,
				pagingS = Math.floor((pageData.page) / pageGroupEa)*pageGroupEa, 
				pagingE = Math.min(num, pagingS+pageGroupEa),
				pageList = [],
				curr;
			pageList.push({code:'|<', link:'0', css:'_first'+(pagingS==0?' _dis':'')});
			pageList.push({code:'<', link:String(Math.max(pagingS-pageGroupEa, 0)), css:'_before'+(pagingS==0?' _dis':'')});
			for (var i=pagingS; i<pagingE; i++) {
				curr = i==pageData.page;
				pageList.push({code:String(i+1), link:(curr?'':''+i), css:'_page'+(curr?' _curr':'')});
			}
			pageList.push({code:'>', link:String(Math.min(pagingS+pageGroupEa, num-1)), css:'_after'+(pagingS+pageGroupEa>num?' _dis':'')});
			pageList.push({code:'>|', link:''+num, css:'_last'+(pagingS+pageGroupEa>num-1?' _dis':'')});
			return pageList;
		},
		changePage(e, pageNo) {
			e.preventDefault();
			let vm = this;
			if (pageNo) {
				vm.page = pageNo;
				vm.loadData();
			}
		},
		fileTypeName(fileType){
			var tp = this.$store.state.fileTypeName(fileType);
			return tp;
		},
	},
	mounted(){
		let vm = this;
		vm.loadData();
	}
}
</script>
