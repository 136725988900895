<template>
	<div class="main_wrap _da">
		<data-list 
		/>
	</div>
</template>

<script>
import DataList from '@/components/DataList'
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
  	name: 'Data',
	props: {
		
	},
	data: () => ({
	}),
	components:{
		DataList
	},
	computed:{
	},
	methods: {
	},
	mounted(){
	},
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.data[lang],
			desc: '하이바이블의 자료실입니다. 예배 후 활동 내용 및 자료의 PDF열람 또는 다운로드를 하실 수 있습니다.',
			keyword: '하이바이블, hibible, 예배후활동, 자료 다운로드, file, download, 색칠공부',
			thumb: '/hibible/resource/images/data_acc_r.svg',
		});
	},
}
</script>