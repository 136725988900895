<template>
	<li :class="'_pos_'+index+' _type_'+fileType">
 		<div class="item_wrap">
			<span class="info_week">
				<span class="info_ep">E{{episode}}.</span>
				{{weekStr}}
			</span>
			<span class="info_thumb"><img :src="'https://img.youtube.com/vi/'+youtubeCode+'/hqdefault.jpg'" :alt="title+' 이미지'" /></span>
			<span class="info_type">
				<span class="info_title">{{fileTypeName(fileType)}}</span>
			</span>
			<span class="info_subject">
				<span class="info_title">{{title}}</span>
				<span class="info_sub" v-html="bibleStr" />
			</span>
			<span class="info_btns">
				<a 
					:href="fileURL" 
					title="자료 열기" 
					v-on:click="preview($event, fileURL, episode+'.'+title+' - '+fileTypeName(fileType))" 
					class="link_data link_view"
				>
					{{viewFileStr}}
				</a>
				<a 
					:href="fileURL" 
					title="자료 다운로드" 
					class="link_data link_download" 
					@click.prevent="downloadItem(fileURL, fileName)"
					download
				>
					{{downloadStr}}
				</a>
			</span>
		</div>
	</li>
</template>

<script>
import axios from 'axios'
import langCommon from '@/language/common'

export default {
    name: 'DataList',
	components: {
	},
    data: () => ({
		items:[]
    }),
	props:{
		index:{type: Number},
		episode:{type: Number},
		dateYear:{type: Number},
		dateMonth:{type: Number},
		dateWeek:{type: Number},
		bookCode:{type: Number},
		chapter:{type: Number},
		verseStart:{type: Number},
		verseEnd:{type: Number},
		youtubeCode:{type: String, default: ''},
		fileName:{type: String, default: ''},
		fileType:{type: String, default: ''},
		fileURL:{type: String, default: ''},
		title:{type: String, default: ''}
	},
    computed: {
        lang() {
            return this.$store.state.lang;
        },
		weekStr(){
			return this.$store.state.weekForm(this.dateYear, this.dateMonth, this.dateWeek);
		},
		bibleStr(){
			return this.$store.state.bibleForm(this.bookCode, this.chapter, this.verseStart, this.verseEnd);
		},
		viewFileStr() {
			return langCommon.filePreview();
		},
		downloadStr() {
			return langCommon.fileDownload();
		},
    },
	methods: {
		viewItem(itemID, e){
			e.preventDefault();
			this.$emit('select-item', itemID);
		},
		preview(e, url, title) {
			e.preventDefault();
			this.$store.state.openPreview(url, title);
		},
		fileTypeName(fileType){
			var tp = this.$store.state.fileTypeName(fileType);
			return tp;
		},
		downloadItem (url, label) {
			axios.get(url, { responseType: 'blob' })
			.then(response => {
				const blob = new Blob([response.data], { type: 'application/pdf' })
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = label;
				link.click();
				URL.revokeObjectURL(link.href);
			}).catch(console.error);
		}
	},
	mounted(){
	}
}
</script>
